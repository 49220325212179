//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    computed: {
        totals() {
            return this.$store.state.cart.cart.prices;
        },
        discountTotal() {
            const customDiscounts = (this.totals.discounts || []).map((discount) => discount.amount.value).reduce((a, b) => a + b, 0);
            const regularDiscounts = [...this.$store.state.cart.cart.items]
                .map((curr) => {
                    let priceDelta = 0;
                    if (curr.product.price_tiers.length) {
                        const tierPriceRules = curr.product.price_tiers
                            .filter((tier) => tier.quantity <= curr.quantity)
                            .sort((tierA, tierB) => tierB.quantity - tierA.quantity);
                        const bestTierPriceRule = tierPriceRules.length > 0 ? tierPriceRules[0] : null;
                        const bestTierDelta = bestTierPriceRule
                            ? curr.product.price_range.minimum_price.regular_price.value - bestTierPriceRule.final_price.value
                            : 0;
                        const bestTierTotal = bestTierDelta * curr.quantity;

                        if (priceDelta < bestTierTotal) {
                            priceDelta = bestTierTotal;
                        }
                    }

                    if (curr.product.price_range.minimum_price.regular_price.value > curr.product.price_range.minimum_price.final_price.value) {
                        const discountedPrice =
                            (curr.product.price_range.minimum_price.regular_price.value - curr.product.price_range.minimum_price.final_price.value) *
                            curr.quantity;

                        if (priceDelta < discountedPrice) {
                            priceDelta = discountedPrice;
                        }
                    }

                    return priceDelta;
                })
                .reduce((acc, curr) => acc + curr, 0);

            return regularDiscounts + customDiscounts;
        },
        shipping() {
            try {
                const selected = this.$store.state.cart.cart.shipping_addresses[0].selected_shipping_method;
                return {
                    /* @todo Magento ignores tax settings for selected shipping method in graphql response */
                    value: Math.round(selected.amount.value * 1.255 * 1000) / 1000,
                    currency: selected.amount.currency,
                };
            } catch (e) {
                return false;
            }
        },
    },
};
