//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: [],
    data() {
        return {};
    },
    computed: {
        awayFromFreeShipping() {
            /* @todo peter move to storeconfig */
            const amount = 30 - this.$store.state.cart.cart.prices.grand_total.value;

            return amount;
        },
    },
    methods: {},
};
