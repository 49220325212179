//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'ErrorPage',
    props: {
        /**
         * http status code
         */
        statusCode: {
            type: [Number, String],
            required: false,
            default: 'Oops!',
        },
        /**
         * Title text
         */
        title: {
            type: String,
            required: true,
        },
        /**
         * Error message
         */
        message: {
            type: String,
            required: true,
        },
        /**
         * Actions/links user can take
         */
        actions: {
            type: Array,
            required: true,
        },
    },
    methods: {
        goBack() {
            this.$router.back();
        },
    },
};
