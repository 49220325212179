//
//
//
//
//
//
//
//

export default {
    middleware: 'auth',
};
