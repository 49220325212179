export default function (req, res, next) {
    try {
        if (req && req.headers && req.headers['user-agent'].includes('facebookexternalhit')) {
            res.writeHead(200, 'OK');
            res.end();
        } else {
            next();
        }
    } catch (error) {
        next();
    }
}
