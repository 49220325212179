//
//
//

export default {
    props: {
        error: {
            type: Object,
            required: true,
            default: () => ({
                statusCode: 'default',
            }),
        },
    },
    computed: {
        parsedError() {
            return {
                statusCode: this.error.statusCode,
                title: this.$t(`error.${this.error.statusCode}.title`),
                message: this.$t(`error.${this.error.statusCode}.message`),
                actions: [
                    {
                        title: this.$t('error.action.home'),
                        to: '/',
                    },
                    {
                        title: this.$t('error.action.login'),
                        to: '/account',
                    },
                ],
            };
        },
    },
};
