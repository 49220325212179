export default function (req, res, next) {
    if (req && req.headers) {
        const ip = req.headers['x-appengine-user-ip'];
        const whitelist = ['188.117.8.33', '85.76.36.110'];
        if (req.headers['host'] && req.headers['host'].includes('localhost')) {
            next();
            return;
        }
        const valid = req.headers['host'] && whitelist.includes(ip);
        if (valid) {
            next();
            return;
        } else {
            res.writeHead(503, { 'Content-Type': 'text/html' });
            res.write(
                `<html><head><meta charset="UTF-8"></head><body><b>Huoltohenkilöstö huoltaa Mobiilitukkua parhaillaan. Palaa hieman myöhemmin uudestaan entistä ehompaan kauppaamme! :)</b> </body></html>`,
            );
            res.end();
            return;
        }
    }
}
