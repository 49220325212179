//
//
//
//
//
//

export default {
    props: {
        theme: {
            type: String,
            required: false,
            default: 'default',
            validator(value) {
                return ['default', 'info', 'success'].indexOf(value) !== -1;
            },
        },
        iconCode: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        colors() {
            if (this.theme === 'info') {
                return 'bg-[#8b69ff1a] border-[#8B69FF]';
            }
            if (this.theme === 'success') {
                return 'bg-[#00b4881a] border-[#00B488]';
            }
            return 'bg-[#E1EAEB] border-[#2DB1C8]';
        },

        textColors() {
            if (this.theme === 'info') {
                return 'text-[#8B69FF] mr-2';
            }
            if (this.theme === 'success') {
                return 'text-[#00B488] mr-2';
            }
            return 'mr-2';
        },
    },
    data() {
        return {};
    },
};
