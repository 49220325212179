export default function (req, res, next) {
    if (req && req.headers && req.headers.host) {
        const originalHost = req.headers.host;
        const isWWW = originalHost.startsWith('www.');
        const destinationHost = isWWW ? originalHost.substring(4) : originalHost;
        if (isWWW) {
            res.writeHead(301, {
                Location: `https://${destinationHost}${req.url}`,
            });
            res.end();
        } else {
            next();
        }
    } else {
        next();
    }
}
