const searchPath = '/search';

export default function (req, res, next) {
    if (req && req.originalUrl && req.originalUrl.startsWith(searchPath)) {
        const searchUrlComponents = req.originalUrl.split(searchPath);
        if (searchUrlComponents.length < 2 || searchUrlComponents[1].length === 0) {
            next();
        } else {
            const searchQuery = req.originalUrl.substring(searchPath.length);
            if (searchQuery.startsWith('?')) {
                next();
            } else {
                try {
                    const decodedQuery = decodeURIComponent(searchQuery);
                    if (decodedQuery !== searchQuery) {
                        // Prevent circular redirects
                        const protocol = req.headers.host.startsWith('localhost') ? 'http' : 'https';
                        res.writeHead(301, {
                            Location: `${protocol}://${req.headers.host}${searchPath}${decodedQuery}`,
                        });
                        res.end();
                    } else {
                        next();
                    }
                } catch (e) {
                    next();
                }
            }
        }
    } else {
        next();
    }
}
