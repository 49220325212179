//
//
//
//
//
//

export default {
    mounted() {
        if (!this.$config.UPSY) return;

        const token = this.$config.UPSY;
        this.$nextTick(function () {
            (function () {
                var e = function (c, b, d) {
                        var a = document.createElement('script');
                        a.src = c;
                        a.onload = b;
                        a.onreadystatechange = b;
                        a.defer = true;
                        d.appendChild(a);
                    },
                    f = function () {
                        setTimeout(() => upsy_sdk.init(token), 7000);
                    };
                e('https://upsy-widget.upsyshopping.com/static/upsy.js', f, document.body);
            })();

            // Start of Zendesk Chat Script
            if (typeof window !== 'undefined' && typeof window.$zopim === 'undefined') {
                setTimeout(
                    () =>
                        (function (d, s) {
                            var z = (window.$zopim = function (c) {
                                    z._.push(c);
                                }),
                                $ = (z.s = d.createElement(s)),
                                e = d.getElementsByTagName(s)[0];
                            z.set = function (o) {
                                z.set._.push(o);
                            };
                            z._ = [];
                            z.set._ = [];
                            $.async = !0;
                            $.setAttribute('charset', 'utf-8');
                            $.src = 'https://v2.zopim.com/?2ksMaBW27AwIYHlfov2xv2pPBv5zlklW';
                            $.defer = true;
                            z.t = +new Date();
                            $.type = 'text/javascript';
                            e.parentNode.insertBefore($, e);
                        })(document, 'script'),
                    7500,
                );
            }
            // End of Zendesk Chat Script

            /*
             * This script was provided from Upsy as they dont have any way of setting the z-index with any options.
             * */
            function changeUpsyZIndex(zIndex) {
                const upsyDiv = document.querySelector('.upsy-plugin');
                if (upsyDiv) {
                    const upsyChildDiv = upsyDiv.shadowRoot.querySelector('div');
                    if (upsyChildDiv) {
                        upsyChildDiv.style.zIndex = zIndex;
                    } else {
                        setTimeout(() => changeUpsyZIndex(zIndex), 500);
                    }
                } else {
                    setTimeout(() => changeUpsyZIndex(zIndex), 500);
                }
            }

            changeUpsyZIndex(19);
        });
    },
    watch: {
        '$store.state.upsy.updateCount'() {
            if (!this.$config.UPSY) return;
            const token = this.$config.UPSY;
            if (upsy_sdk) {
                upsy_sdk.init(token);
            }
        },
    },
};
