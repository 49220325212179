//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    computed: {
        backLinkLabel() {
            if (this.$route.path === '/checkout') {
                return this.$t('header.backlinklabel.checkout');
            }

            if (this.$route.path === '/cart') {
                return this.$t('header.backlinklabel.cart');
            }

            return this.$t('header.backlinklabel.default');
        },
        backLinkRoute() {
            if (this.$route.path === '/checkout') {
                return '/cart';
            }

            return '/';
        },
    },
};
