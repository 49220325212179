export default {
    methods: {
        isFullUrl(value) {
            try {
                const url = new URL(value);

                return !!url.href;
            } catch {
                return false;
            }
        },
        formatUrl(link) {
            if (!link) {
                return '/';
            }

            if (link.startsWith('#') || this.isFullUrl(link)) {
                return link;
            }

            const route = {};
            let url = link;

            if (url && url.includes('?') && url.split('?').length === 2) {
                if (!link.startsWith('/')) {
                    url = `/${link}`;
                }

                const path = url.split('?')[0];
                const queryString = url.split('?')[1];
                const query = new URLSearchParams(queryString);

                if (query) {
                    route.query = Object.fromEntries(query.entries());
                    route.path = path;
                } else {
                    route.path = url;
                }
            } else if (url) {
                if (!link.startsWith('/')) {
                    url = `/${link}`;
                }

                route.path = url;
            }

            return this.localePath(route);
        },
    },
};
